import {
  Directive,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { TokenService } from '../core/services/token.service';

/**
 * @description Shows the item only if the user navigating is an admin
 */

@Directive({
  selector: '[byIsAdmin]',
  standalone: true,
})
export class IsAdminDirective implements OnInit {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private tokenService: TokenService,
  ) {}

  isAdmin = this.tokenService.isAdmin();

  ngOnInit(): void {
    if (this.isAdmin) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
